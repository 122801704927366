import React from "react"
import { PropTypes } from "prop-types"

function Video({ videoSrc, videoType }) {
  return (
    <video
      style={{ clipPath: "inset(1px 1px)" }}
      autoPlay
      muted
      loop
      playsInline
      controls={false}
    >
      <source src={videoSrc} type={videoType} />
    </video>
  )
}

Video.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  videoType: PropTypes.string.isRequired,
}

export default Video
