import React from "react"
import styled from "styled-components"
import SlideInWrapper from "./SlideInWrapper"
import Container from "../atoms/Container"
import { PropTypes } from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image";


const Holder = styled.div`
  display: flex;
  flex-direction: column;
  @media (${(props) => props.theme.breakpoints.lg}) {
    flex-direction: row;
  }
`

const ImageHolder = styled.div`
  margin-bottom: 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    flex: ${(props) => props.flex};
    margin: 0 1rem 0 0;
    &:last-child {
      margin: 0;
    }
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`

function WorkMultipleImage({ images }) {
  return (
    <SlideInWrapper>
      <Container large>
        <Holder>
          <StaticQuery
            query={graphql`
              query {
                allImageSharp {
                  edges {
                    node {
                      id
                      resize {
                        originalName
                      }
                      gatsbyImageData(
                        width: 1200
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                       )
                    }
                  }
                }
              }
            `}
            render={(data) =>
              images.map((image) => {
                const fluidImage = data.allImageSharp.edges.find(
                  (edge) =>
                    edge.node.resize.originalName ===
                    image.replace(/^.*[\\/]/, "")
                )
                if (!fluidImage) {
                  return null
                }
                return (
                  <ImageHolder
                    key={fluidImage.node.id}
                    // flex={fluidImage.node.fluid.aspectRatio}
                  >
                    <GatsbyImage image={fluidImage.node.gatsbyImageData} alt="Craig Walker"/>
                  </ImageHolder>
                )
              })
            }
          />
        </Holder>
      </Container>
    </SlideInWrapper>
  )
}

WorkMultipleImage.propTypes = {
  images: PropTypes.array.isRequired,
}

export default WorkMultipleImage
