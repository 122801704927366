import React from "react"
import styled from "styled-components"
import SlideInWrapper from "./SlideInWrapper"
import Container from "../atoms/Container"
import {PropTypes} from "prop-types"
import {graphql, StaticQuery} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image";

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (${(props) => props.theme.breakpoints.lg}) {
    flex-direction: row;
  }
`

const ImageHolder = styled.div`
  margin-bottom: 1rem;
  width: 95%;
  max-width: 12rem;
  @media (${(props) => props.theme.breakpoints.xs}) {
    width: 350px;
  }
  @media (${(props) => props.theme.breakpoints.lg}) {
    width: unset;
    flex: ${(props) => props.flex};
    margin: 0 1rem 0 0;
    &:last-child {
      margin: 0;
    }

    h5 {
      margin: 1.5rem 0 0;
    }
  }

  h5 {
    margin: 1.5rem 0;
    text-align: center;
    font-family: "Lora", serif;
    font-weight: 600;
    //font-size: 28px !important;
    color: #454545;
  }
`

function WorkMultipleImage({images, captions}) {
  return (
    <SlideInWrapper>
      <Container large>
        <Holder>
          <StaticQuery
            query={graphql`
              query {
                allImageSharp {
                  edges {
                    node {
                      id
                      resize {
                        originalName
                      }
                      gatsbyImageData(
                        width: 1200
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            `}
            render={(data) =>
              images.map((item) => {
                const fluidImage = data.allImageSharp.edges.find(
                  (edge) =>
                    edge.node.resize.originalName ===
                    item.image.replace(/^.*[\\/]/, "")
                )
                if (!fluidImage) {
                  return null
                }
                return (
                  <ImageHolder
                    key={fluidImage.node.id}
                    // flex={fluidImage.node.fluid.aspectRatio}
                  >
                    <GatsbyImage image={fluidImage.node.gatsbyImageData}/>
                    <h5>{item.text}</h5>
                  </ImageHolder>
                )
              })
            }
          />
        </Holder>
      </Container>
    </SlideInWrapper>
  )
}

WorkMultipleImage.propTypes = {
  images: PropTypes.array.isRequired,
}

export default WorkMultipleImage
