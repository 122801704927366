import React from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ContentHolder from "../components/organisms/ContentHolder"
import Image from "../components/atoms/Image"
import Video from "../components/atoms/Video"
import vid from "../../static/videos/ttc-youth.mp4"
import WorkTextBlock from "../components/molecules/WorkTextBlock"
import RelatedWork from "../components/organisms/RelatedWork"
import WorkImage from "../components/molecules/WorkImage"
import WorkMultipleImage from "../components/molecules/WorkMultipleImage"
import WorkMultipleImageWithCaptions from "../components/molecules/WorkMultipleImageWithCaptions"
import ArrowText from "../components/atoms/ArrowText"
import Numbers from "../components/organisms/Numbers"
import Number from "../components/molecules/Number"
import thumb from "../../static/images/design-youth-ttc-007.png"
import GetInTouch from "../components/molecules/GetInTouch"
import styled from "styled-components"

const GreyWrapper = styled.div`
  background: ${(props) => props.theme.colours.grey};
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 6rem 0;
  }
`

function DesigningForYouth() {
  return (
    <>
      <Seo
        title="Co-creating better digital services for young people | Craig Walker x TTC Labs"
        image={thumb}
        description="How do we empower, protect and guide young people from different communities around the world to navigate the shifting digital landscape?"
      />

      <Splash
        text={<>Co-creating better digital services for young people</>}
        work={true}
      >
        <p>
          <b>
            How do we empower, protect and guide young people from different
            communities around the world to navigate the shifting digital
            landscape?
          </b>
        </p>
        <p>
          Craig Walker collaborated with TTC Labs on a series of co-creation
          workshops with youth leaders and policy makers in Australia, the
          United States and the UK, in order to create design principles
          informed by local contexts.
        </p>
        <p>
          This research work was then used to inform new key principles, digital
          user journeys, a set of user personas and a report that lays the
          framework for the tech industry to be more informed when designing
          digital services for young people.
        </p>
        <ArrowText>
          <p>Learn More</p>
        </ArrowText>
      </Splash>

      <ContentHolder>
        <WorkImage transparent>
          <Image imgName="ttc-youth-10.png" />
        </WorkImage>

        <Numbers>
          <Number number="40+" text="Youth leaders consulted" />
          <Number number={3} text="Design jams synthesised" />
          <Number number={5} text="Personas published" />
        </Numbers>

        <WorkTextBlock>
          <h2>Exploring privacy and safety through co-creators</h2>
          <p>
            The two-day day co-creation workshop called on more than 40 youth
            leaders and policy makers to collaborate on a series of tasks and
            briefs developed by Craig Walker to help shed light on the the
            sticking points of a young person's digital journey now and in the
            future. The team gathered and synthesised the insights into a
            database with two other Jams globally (UK and USA), informing a
            series of principles, a report, a digital journey and a set of
            personas.
          </p>
        </WorkTextBlock>

        <WorkMultipleImage
          images={[
            "design-youth-ttc-002.png",
            "ttc-youth-5.jpg",
            "ttc-youth-4.jpg",
            "ttc-youth-6.jpg",
          ]}
        />
        <WorkMultipleImage
          images={[
            "design-youth-ttc-006.png",
            "design-youth-ttc-007.png",
            "design-youth-ttc-008.png",
            "ttc-youth-8.jpg",
          ]}
        />

        <WorkTextBlock>
          <h2>Transforming research findings into useable tools</h2>
          <p>
            After collating the findings from co-creator workshops in Australia,
            the UK and the US, Craig Walker developed series of projects to turn
            data and prototypes into working tools and guides for the tech
            industry.
          </p>
          <p>
            This ever-evolving set of tools is helping grow a conversation
            around how designers should approach developing for youth user
            experience in digital services.
          </p>
        </WorkTextBlock>

        <GreyWrapper>
          <WorkMultipleImageWithCaptions
            images={[
              {
                image: "ttc-youth-7.png",
                text: "Key principles",
              },
              {
                image: "ttc-youth-3.png",
                text: "Digital journey",
              },
              {
                image: "ttc-youth-2.png",
                text: "Persona set",
              },
              {
                image: "ttc-youth-1.png",
                text: "Research report",
              },
            ]}
          />
        </GreyWrapper>

        <WorkTextBlock>
          <h2>Engaging with complex ideas visually</h2>
          <p>
            Alongside the release of the Youth Report, Personas and other tools,
            Craig Walker created a visual explainer as an engaging introduction
            to the topic for designers and general public alike. The explainer
            tells the stories of three personas through illustration and
            animation across desktop and mobile.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Video videoType="video/mp4" videoSrc={vid} />
        </WorkImage>

        <GetInTouch dataView="KnowNow" />
        <RelatedWork exclude="dfy-ttc" />
      </ContentHolder>
    </>
  )
}

export default DesigningForYouth
